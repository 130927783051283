// import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";

// const Countrychart = ({ chartType,countriesgraph }) => {
//   const [series, setSeries] = useState([{ data: [10, 20, 30, 40, 50] }] ); 
//   const [options, setOptions] = useState({
//     chart: {
//       type: "bar",
//       height: 350,
//       background: "#000", 
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         distributed: true, 
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       style: {
//         fontSize: "12px",
//         colors: ["#545454"],
//       },
//       formatter: function (val, { seriesIndex, dataPointIndex }) {
//         const dataValue = parseFloat(val);
//         if (!isNaN(dataValue)) {
//           const formattedValue =
//             chartType === "amount" ? `$${dataValue.toLocaleString()}` : val;
//           return formattedValue;
//         }
//         return "";
//       },
//     },
//     xaxis: {
//       categories: ["USA", "China", "Japan", "Germany", "Brazil"] ,
//       labels: {
//         style: {
//           colors: ["#fff"],
//           fontSize: "14px",
//         },
//         rotateAlways: true,
//         offsetY: 0,
//         offsetX: 0,
//         rotate: -35,
//       },
//     },
//     yaxis: {
//       labels: {
//         style: {
//           colors: ["#fff"],
//           fontSize: "14px",
//         },
//       },
//     },
//     grid: {
//       show: false,
//     },
//     stroke: {
//       show: false,
//     },
//     fill: {
//       colors: ["#FFF"], 
//     },
//     legend: {
//       show: false, 
//     },
//     tooltip: {
//       enabled: true,
//       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//         const category = w.globals.labels[dataPointIndex]; 
//         const capitalizedCategory =
//           category.charAt(0).toUpperCase() + category.slice(1);
//         const value = series[seriesIndex][dataPointIndex]; 
//         const title = chartType === "amount" ? "Purchased Amount" : "Purchases";
//         return `
//           <div style="background-color: #fff; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
//             <div style="font-weight: bold;">${capitalizedCategory}</div>
//             <div>${title}: ${
//           chartType === "amount" ? `$${value.toLocaleString()}` : value
//         }</div>
//           </div>
//         `; 
//       },
//     },
//   });

//   return (
//     <div id="chart">
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="bar"
//         height={window.innerWidth > 600 ? 350 : 200}
//       />
//     </div>
//   );
// };

// export default Countrychart;


import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Countrychart = ({ chartType, countriesgraph, loader }) => {
  const [series, setSeries] = useState([{ data: [] }]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      background: "#000",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#545454"],
      },
      formatter: function (val) {
        const dataValue = parseFloat(val);
        if (!isNaN(dataValue)) {
          const formattedValue =
            chartType === "amount"
              ? `$${dataValue.toFixed(2).toLocaleString()}`
              : dataValue.toFixed(2).toLocaleString();
          return formattedValue;
        }
        return "";
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: ["#fff"],
          fontSize: "14px",
        },
        rotateAlways: true,
        offsetY: 0,
        offsetX: 0,
        rotate: -35,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      show: false,
    },
    fill: {
      colors: ["#FFF"],
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.globals.labels[dataPointIndex];
        const capitalizedCategory =
          category.charAt(0).toUpperCase() + category.slice(1);
        const value = series[seriesIndex][dataPointIndex];
        const title = chartType === "amount" ? "Purchased Amount" : "Purchases";
        const formattedValue =
          chartType === "amount"
            ? `$${value.toFixed(2).toLocaleString()}`
            : value.toFixed(2).toLocaleString();
        return `
          <div style="background-color: #fff; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
            <div style="font-weight: bold;">${capitalizedCategory}</div>
            <div>${title}: ${formattedValue}</div>
          </div>
        `;
      },
    },
  });

  useEffect(() => {
    if (countriesgraph && countriesgraph.length > 0) {
      const categories = countriesgraph
        .map((item) => item._id)
        .filter((item) => item !== null && item !== "Anonymous");
      const values = countriesgraph
        .map((item) => item.totalValue)
        .filter(
          (_, index) =>
            countriesgraph[index]._id !== null &&
            countriesgraph[index]._id !== "Anonymous"
        );

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
        },
      }));

      setSeries([{ data: values }]);
    }
  }, [countriesgraph, chartType]);

  return (
    <div id="chart">
      {/* {loader ? (
        <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
          Loading data...
        </div>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={window.innerWidth > 600 ? 350 : 200}
        />
      )} */}
      {loader ? (
        <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
          Loading data...
        </div>
      ) : countriesgraph?.length === 0 ? (
        <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
          No data found.
        </div>
      ) : (
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={window.innerWidth > 600 ? 350 : 350}
          />
        </div>
      )}
    </div>
  );
};

export default Countrychart;


