// import React from 'react';
// import ReactApexChart from 'react-apexcharts';

// const ValueLockedGraph = ({tvlgraph, loader}) => {
//     console.log(tvlgraph,'tvlgraph');
//     const state = {
//         series: [{
//             name: 'series1',
//             data: [0, 100000, 250000, 500000, 750000, 1000000, 1250000, 1500000, 1750000, 2000000]
//         }],
//         options: {
//             chart: {
//                 height: 350,
//                 type: 'area',
//                 foreColor: '#fff'
//             },
//             dataLabels: {
//                 enabled: false
//             },
//             stroke: {
//                 width: 1.5,
//                 curve: 'smooth',
//                 colors: ['#35D175']
//             },
//             fill: {
//                 type: 'solid',
//                 colors: ['#082012']
//             },
//             xaxis: {
//                 categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
//             },
//             yaxis: {
//                 min: 0,
//                 max: 2000000,
//                 labels: {
//                     formatter: function (value) {
//                         const yCategories = [
//                             "$0", "$100,000", "$250,000", "$500,000",
//                             "$750,000", "$1,000,000", "$1,250,000",
//                             "$1,500,000", "$1,750,000", "$2,000,000"
//                         ];
//                         const step = 200000;
//                         const index = Math.round(value / step);
//                         return yCategories[index] || value;
//                     }
//                 },
//                 tickAmount: 9,
//             },
//             grid: {
//                 borderColor: '#11171F'
//             }
//         }
//     };

//     return (
//         <>
//             <div>
//                 <div id="chart">
//                     <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
//                 </div>
//                 <div id="html-dist"></div>
//             </div>
//         </>
//     );
// }

// export default ValueLockedGraph;

//  by year

// import React, { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const ValueLockedGraph = ({ tvlgraph, loader }) => {
//     console.log(tvlgraph,'tvlgraph');
//     const [series, setSeries] = useState([{ name: 'series1', data: [] }]);
//     const [options, setOptions] = useState({
//         chart: {
//             height: 350,
//             type: 'area',
//             foreColor: '#fff',
//         },
//         dataLabels: {
//             enabled: false,
//         },
//         stroke: {
//             width: 1.5,
//             curve: 'smooth',
//             colors: ['#35D175'],
//         },
//         fill: {
//             type: 'solid',
//             colors: ['#082012'],
//         },
//         xaxis: {
//             categories: [],
//         },
//         yaxis: {
//             min: 0,
//             max: 2000000,
//             labels: {
//                 formatter: function (value) {
//                     const yCategories = [
//                         "$0", "$100,000", "$250,000", "$500,000",
//                         "$750,000", "$1,000,000", "$1,250,000",
//                         "$1,500,000", "$1,750,000", "$2,000,000"
//                     ];
//                     const step = 200000;
//                     const index = Math.round(value / step);
//                     return yCategories[index] || value;
//                 }
//             },
//             tickAmount: 9,
//         },
//         grid: {
//             borderColor: '#11171F',
//         },
//         tooltip: {
//             enabled: true,
//             custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//                 const category = w.globals.labels[dataPointIndex];
//                 const value = series[seriesIndex][dataPointIndex];
//                 const formattedValue = `$${value.toFixed(2).toLocaleString()}`;
//                 return `
//                   <div style="background-color: #fff; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
//                     <div style="font-weight: bold;">${category}</div>
//                     <div>Total Value: ${formattedValue}</div>
//                   </div>
//                 `;
//             },
//         },
//     });

//     useEffect(() => {
//         // if (tvlgraph && tvlgraph.length > 0) {
//             // Extract months and values
//             const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
//             const mappedData = Array(12).fill(0); // Initialize an array for 12 months with zero values

//             tvlgraph.forEach(item => {
//                 const monthIndex = item._id.month - 1; // Adjusting month index to be 0-based
//                 if (monthIndex >= 0 && monthIndex < 12) {
//                     mappedData[monthIndex] = item.totalValue;
//                 }
//             });

//             setSeries([{ name: 'series1', data: mappedData }]);
//             setOptions(prevOptions => ({
//                 ...prevOptions,
//                 xaxis: {
//                     ...prevOptions.xaxis,
//                     categories: months,
//                 },
//             }));
//         // }
//     }, [tvlgraph]);

//     return (
//         <div className="chart-container">
//             {loader ? (
//                 <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
//                     Loading data...
//                 </div>
//             ) : tvlgraph.length === 0 ? (
//                 <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
//                   No data found.
//                 </div>
//               ) : (
//                 <div id="chart">
//                     <ReactApexChart options={options} series={series} type="area" height={350} />
//                 </div>
//             )}
//             <div id="html-dist"></div>
//         </div>
//     );
// };

// export default ValueLockedGraph;


import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ValueLockedGraph = ({ tvlgraph, loader }) => {
    const mapTvlData = () => {
        if (tvlgraph.length === 0) return [];
        return tvlgraph.map(item => item.totalValue);
    };

    const mapCategories = () => {
        if (tvlgraph.length === 0) return [];
        return tvlgraph.map(item => {
            if (item._id.hour !== undefined) return `Hour ${item._id.hour}`;
            if (item._id.day !== undefined) return `Day ${item._id.day}`;
            if (item._id.month !== undefined) return `Month ${item._id.month}`;
            return item._id;
        });
    };

    const state = {
        series: [{
            name: 'Total Value Locked',
            data: mapTvlData(),
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                foreColor: '#fff',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1.5,
                curve: 'smooth',
                colors: ['#35D175'],
            },
            fill: {
                type: 'solid',
                colors: ['#082012'],
            },
            xaxis: {
                categories: mapCategories(),
            },
            yaxis: {
                min: 0,
                labels: {
                    formatter: function (value) {
                        return `$${value.toLocaleString()}`;
                    },
                },
                tickAmount: 10,
            },
            grid: {
                borderColor: '#11171F',
            },
        },
    };

    return (
        <div>
            <div id="chart">
                {loader ? (
                    <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
                        Loading data...
                    </div>
                ) : tvlgraph.length === 0 ? (
                    <div style={{ color: "#fff", textAlign: "center", padding: "20px" }}>
                        No data found.
                    </div>
                ) : (
                    <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
                )}
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ValueLockedGraph;


