import React, { useState } from "react";
import "./login.scss";
import Header from "../landing/Header/Header";
// import { Link } from "react-router-dom";
// import Environment from "../../utils/Environment";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { toast } from 'react-toastify';
import Environment from "../../utils/Enviroment";

const Login = () => {
  const api_url = Environment.api_url;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [errorpassword, setErrorPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPass, setShowPass] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const userLogin = (e) => {
    if (email.length === 0) {
      setEmailErrorRegister("Email is Required");
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister("Email is invalid");
    } else if (password.length === 0) {
      setErrorPassword("Password is Required");
    } else {
      setIsLoading(true);
      var data = JSON.stringify({
        email: email,
        password: password,
        // rememberMe: rememberMe,
      });
      var config = {
        method: "post",
        url: `${api_url}/admins/auth/admins/signin`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          const resData = response?.data?.data;
          localStorage.setItem("accessToken", resData?.accessToken);
          localStorage.setItem("refreshToken", resData?.refreshToken);
          localStorage.setItem("userId", resData?.user?._id);
          navigate("/landing")
          toast.success("Admin! You have successfully logged in.");

        })
        .catch(function (error) {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("An unexpected error occurred.");
          }
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state
        });
    }
  };

  return (
    <>
      <div className="wrapper-header">
        <Header />
        <section className="login">
          <div className="login-card">
            <h6 className="heading-text">log into your Admin account</h6>
            <div className="main-body">
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    autoComplete="off" value={email} onChange={(e) => { setEmail(e.target.value); setEmailErrorRegister(""); setError("") }}
                  />
                  <label>Email</label>
                </div>
                {emailerrorregister ? (
                  <p className="text-danger mt-2">{emailerrorregister}</p>
                ) : null}
              </div>
              <div className="option-field">
                <div className="material-textfield">
                  <input
                    placeholder="Enter your password"
                    value={password} autoComplete="off" onChange={(e) => { setPassword(e.target.value); setErrorPassword(""); setError("") }} type={showPass}
                  // type={showPassword ? "text" : "password"}
                  />
                  <label>Password</label>
                </div>
                <img
                  // onClick={() => setShowPassword(!showPassword)}
                  onClick={() => {
                    showPass === "password"
                      ? setShowPass("text")
                      : setShowPass("password");
                  }}
                  style={{ cursor: "pointer" }}
                  src="\eye.svg"
                  alt="img"
                  className="img-fluid password-icon"
                />
                {errorpassword && (
                  <p className="text-danger mb-2">{errorpassword}</p>
                )}
              </div>

              {/* <div className="mycheckbox">
                <label class="innercheckbox" for="firstinput">
                  <input
                    type="checkbox"
                    name="color"
                    id="firstinput"
                    checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span class="innercheckmark"></span>
                </label>
                <p className="innercheckboxpara">
                  Keep me logged in
                </p>
              </div> */}
              <div onClick={userLogin} className="btn-launch">
                <h6>{isLoading ? "Logging in..." : "Log In"}</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M9.20831 6.16665L12.0416 8.99998L9.20831 11.8333M4.95831 6.16665L7.79165 8.99998L4.95831 11.8333"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </section>
      </div>


    </>
  );
};

export default Login;
